import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";

import {
  AiFillAliwangwang,
  AiFillBook,
  AiFillDashboard,
  AiFillExperiment,
  AiFillreport,
} from "react-icons/ai";
// import POS from "./POS";

import VerticalMenu from "../../components/vertical-menu/VerticalMenu";
import HorizontalMenu from "../../components/vertical-menu/HorizontalMenu";

import { getStoresList } from "../../redux/actions/stores";
import { useDispatch } from "react-redux";

import { FiDownloadCloud, FiRotateCcw } from "react-icons/fi";
import Account from "./Account";

import ClickForCashMovement from "./ClickForCashMovement";
import GenerateAccountReport from "./GenerateAccountReport";
import ChientAccountStatement from "./ChientAccountStatement";
import SetupChartOfAccount from "./SetupChartOfAccount";
import AccountReview from "./AccountReview";
import OpeningBalance from "./OpeningBalance";
import Invoice from "./Invoice";
import GenerateInvoice from "./GenerateInvoice";
import { FaRegFileAlt } from "react-icons/fa";
import Payroll from "./Payroll";
import PaymentVoucher from "./PaymentVoucher";
import Memo from "./Memo";
import MemoList from "./MemoList";
import MemoApproval from "./MemoApproval";
import CreatePv from "./CreatePv";
import MemoPDF from "./MemoPDF";

import MemoReviewal from "./MemoReviewal";
import ChartOfAccount from "./ChartOfAccount";

// import AccountSetup from "./Account_Setup";
// import UnitOfMeasure from "./UnitOfMeasurement";

const Tabs = withRouter(({ history, location }) => {
  return (
    <React.Fragment>
      <VerticalMenu title="Menu Items">
        {/* <HorizontalMenu route="/app/report/daily-report">
          <AiFillDashboard size={26} style={{ marginRight: 5 }} />
          Daily Sales
        </HorizontalMenu> */}
        <HorizontalMenu route="/app/account/new">
          <AiFillBook size={26} style={{ marginRight: 5 }} />
          Record Expenses
        </HorizontalMenu>
        <HorizontalMenu route="/app/account/generateinvoice">
          <FaRegFileAlt size={26} style={{ marginRight: 5 }} />
          Generate Invoice
        </HorizontalMenu>
        {/* <HorizontalMenu route="/app/report/bank-report">
          <FiRotateCcw size={26} style={{ marginRight: 5 }} />
          Bank Report
        </HorizontalMenu> */}
        <HorizontalMenu route="/app/account/clickforcashmovement">
          <AiFillExperiment size={26} style={{ marginRight: 5 }} />
          Click For Cash Movement
        </HorizontalMenu>
        <HorizontalMenu route="/app/Account/generateaccountreport">
          <AiFillExperiment size={26} style={{ marginRight: 5 }} />
          Generate Account Report
        </HorizontalMenu>
        <HorizontalMenu route="/app/account/opening-balance">
          <AiFillExperiment size={26} style={{ marginRight: 5 }} />
          Opening Balance
        </HorizontalMenu>
        <HorizontalMenu route="/app/account/chientaccountstatement">
          <AiFillExperiment size={26} style={{ marginRight: 5 }} />
          Client Account Statement
        </HorizontalMenu>
        <HorizontalMenu route="/app/account/setup-chart-of-account">
          <AiFillExperiment size={26} style={{ marginRight: 5 }} />
          Setup chart Of Account
        </HorizontalMenu>
        <HorizontalMenu route="/app/account/chart-of-account">
          <AiFillExperiment size={26} style={{ marginRight: 5 }} />
          Chart Of Account
        </HorizontalMenu>
        <HorizontalMenu route="/app/account/account-review">
          <AiFillExperiment size={26} style={{ marginRight: 5 }} />
          Account Review
        </HorizontalMenu>
        <HorizontalMenu route="/app/account/org-staff">
          <AiFillExperiment size={26} style={{ marginRight: 5 }} />
          Manage Staff
        </HorizontalMenu>
        <HorizontalMenu route="/app/account/payment-voucher">
          <AiFillExperiment size={26} style={{ marginRight: 5 }} />
          Payment Voucher
        </HorizontalMenu>
        <HorizontalMenu route="/app/account/memo-list">
          <AiFillExperiment size={26} style={{ marginRight: 5 }} />
          Memo
        </HorizontalMenu>
        <HorizontalMenu route="/app/account/memo/approval">
          <AiFillExperiment size={26} style={{ marginRight: 5 }} />
          Memo Approval
        </HorizontalMenu>
        <HorizontalMenu route="/app/account/memo/reviewal">
          <AiFillExperiment size={26} style={{ marginRight: 5 }} />
          Memo Review
        </HorizontalMenu>
        
      </VerticalMenu>
    </React.Fragment>
  );
});

function AccountIndex() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStoresList());
  }, [dispatch]);

  return (
    <Row className="m-0">
      <Col md={3}>
        <Tabs />
      </Col>
      <Col md={9}>
        <Switch>
          <Redirect from="/app/account" to="/app/account/new" exact />
          <Route exact path="/app/account/new" component={Account} />
          <Route
            exact
            path="/app/account/generateinvoice"
            component={GenerateInvoice}
          />

          <Route
            exact
            path="/app/account/generateinvoice/invoice"
            component={Invoice}
          />
          <Route
            exact
            path="/app/account/memo/approval"
            component={MemoApproval}
          />

          <Route exact path="/app/account/memo-list" component={MemoList} />
          <Route
            exact
            path="/app/account/clickforcashmovement"
            component={ClickForCashMovement}
          />
          <Route
            exact
            path="/app/account/generateaccountreport"
            component={GenerateAccountReport}
          />
          <Route
            exact
            path="/app/account/opening-balance"
            component={OpeningBalance}
          />
          <Route
            exact
            path="/app/account/chientaccountstatement"
            component={ChientAccountStatement}
          />
          <Route
            exact
            path="/app/account/setup-chart-of-account"
            component={SetupChartOfAccount}
          />
          <Route
            exact
            path="/app/account/chart-of-account"
            component={ChartOfAccount}
          />
          <Route
            exact
            path="/app/account/account-review"
            component={AccountReview}
          />
          <Route exact path="/app/account/org-staff" component={Payroll} />
          <Route
            exact
            path="/app/account/payment-voucher"
            component={PaymentVoucher}
          />
          <Route exact path="/app/account/memo" component={Memo} />
          <Route
            exact
            path="/app/account/memo-pdf/:id"
            component={MemoPDF}
          />
          <Route
            exact
            path="/app/account/create-pv"
            component={CreatePv}
          />
          <Route exact path="/app/account/memo/reviewal" component={MemoReviewal} />
        </Switch>
      </Col>
      <Col>{/* <ShelfAlert /> */}</Col>
    </Row>
  );
}

export default AccountIndex;
