import React from "react";
import { useState } from "react";
import { Col, Input, Label, Row, Table } from "reactstrap";
import CustomButton from "../../app/components/Button";
import CustomCard from "../components/CustomCard";
import { TableBody, TableCell, TableHeaderCell, TableRow } from "evergreen-ui";

export default function ChartOfAccount() {
  const _form = {
    select_account_heard: "",
    search: "",
    account_subhead_code: "",
    account_subhead_description: "",
  };
  const [form, setForm] = useState(_form);
  const [data, setData] = useState([]);

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  const handleAdd = () => {
    // _postApi("classes", form, () => {
    setForm(_form);
    // },
    // (err) => console.log(err)
    // )
    console.log(_form);
  };
  return (
    <div>
      <CustomCard header="Chart of Account">
        <Row>
          <Col md="6">
            <Col md="12">
              <Label>Account type</Label>
              <Input
                type="text"
                name="select_account_heard"
                value={form.select_account_heard}
                onChange={handleChange}
              />
            </Col>
            <Col md="12">
              <Label>Account ID</Label>
              <Input
                type="search"
                placeholder="search..."
                name="search"
                value={form.search}
                onChange={handleChange}
              />
            </Col>
            <Col md="12" className="mt-3">
              <Label>Description</Label>
              <Input
                type="text"
                name="account_subhead_code"
                value={form.account_subhead_code}
                onChange={handleChange}
              />
            </Col>
            <Col md="12" className="mt-3">
              <Label>Running balance</Label>
              <Input
                type="text"
                name="account_subhead_description"
                value={form.account_subhead_description}
                onChange={handleChange}
              />
            </Col>
          </Col>
          <Col md="6">
            <Table>
                <tr>
                    <th>piun</th>
                    <th>piun</th>
                    <th>piun</th>
                    <th>piun</th>
                </tr>
                <tr>
                    <td>happy</td>
                    <td>happy</td>
                    <td>happy</td>
                    <td>happy</td>
                </tr>
            </Table>
          </Col>
        </Row>
        <CustomButton className="mt-3" onClick={handleAdd}>
          Create
        </CustomButton>
      </CustomCard>
    </div>
  );
}
