import React, { useEffect } from 'react';
import './index.css';
import "../src/index.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import LandingPage from './app/landing/Landing';
import PageNotFound from './app/components/PageNotFound';
import Login from './inventria/auths/Login';
import AdminRoutes from './app/index';
import Signup from './inventria/auths/Signup';
import './fonts/OpenSans-Regular.ttf'
import ReactGA from "react-ga4";
import { initUser } from './redux/actions/auth';
import { useHistory } from 'react-router/cjs/react-router.min';


function App() {
  const history = useHistory()
  useEffect(() => {
    ReactGA.initialize("G-C9S1DWKHVN");
    ReactGA.send("pageview");
  }, [])
  useEffect(() => {
    initUser(history,()=>{
      history.push('/app');
    })
  }, [])
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        <Redirect from="/" to="/login" exact />
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/login"  component={Login} />
        <Route exact path="/signup"  component={Signup} />
        <Route path="/app" component={AdminRoutes} />
        <Route component={PageNotFound} />
      </Switch>
    </Router>
  );
} 

export default App;
