import React, { useCallback, useEffect } from 'react'
import { Switch, Route, useHistory,useLocation } from 'react-router-dom'
// import Sales from './sales'
// import CreateProductSetup from './production/ProductSetup';
import NavBar from './components/Navbar'
// import StoreManagement from './store';
// import Account from './account'
// import Production from './production'
// import Purchase from '../inventria/index'
// import Purchase from './purchase';
// import Inventory from './inventory'
// import AdminPage from './admin'
import { useDispatch, useSelector } from 'react-redux'
import { initUser } from '../redux/actions/auth'
import { hasAccess } from './utilities'
// import FinanceIndex from './finance/FinanceIndex'
// import Custormer from './Custormer/index'
import mainRoutes from './routes'
import Dashboard from '../inventria/dashboard/index'
import Reports from '../inventria/dashboard/Reports'
import { gotoApp } from './helper'

function AdminRoutes() {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector((state) => state.auth.user)
  const access = useSelector((state) => state.auth.user.accessTo)
  const location = useLocation()
  
  const navigateUser = useCallback( 
    (access) => {
      // console.error({_access:access})
      dispatch(
        initUser(history, () => {
          if (access && access.length) {
            if (access.includes('Dashboard')) {
              return history.push('/app/dashboard')
            } else {
              gotoApp(access, history)
            }
          }
        }),
      )
    },
    [history, dispatch],
  )

  useEffect(() => {
    // if(location.pathname==='/auth'||location.pathname==='/'){
      navigateUser(access)
    // }
  }, [navigateUser, access])

  return (
    <React.Fragment>
      <NavBar />
      {/* {JSON.stringify(location.pathname)} */}
      <Switch>
        {mainRoutes.map((item, index) => {
          if (user.accessTo && hasAccess(user, item.access)) {
            return (
              <Route key={index} path={item.path} component={item.component} />
            )
          } else return null
        })}
        <Route path="/app/reports/:query_type" component={Reports} />
        <Route path="/app" component={Dashboard} />
      </Switch>
    </React.Fragment>
  )
}

export default AdminRoutes
