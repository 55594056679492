import React, { useCallback, useEffect, useState } from "react";
import CustomCard from "../components/CustomCard";
import { Alert, Badge, Button, Input, Label, Row } from "reactstrap";
import CustomTable from "../../components/CustomTable";
import { useSelector } from "react-redux";
import { _fetchApi, _postApi } from "../../redux/actions/api";
import { GiCancel } from "react-icons/gi";
import { FaCheck, FaEye, FaViber } from "react-icons/fa";
import CustomButton from "../../app/components/Button";
import moment from "moment";
import CustomModal from "../components/CustomModal";
import { View, Text } from "@react-pdf/renderer";
import Loading from "../../app/components/Loading";
import { formatNumber, formatNumber1 } from "../../app/utilities";
import { _customNotify, _warningNotify } from "../../redux/helper";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
function MemoReviewal() {
  const { activeBusiness, user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [memos, setMemos] = useState([]);
  const [remark, setRemark] = useState("");
  const history = useHistory();
  const getMemos = useCallback(() => {
    _fetchApi(
      `/account/get-memo/${activeBusiness.id}/Review`,
      (data) => {
        setLoading(false);
        if (data.success) {
          setMemos(data.results);
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  }, [activeBusiness.id]);
  const [loading1, setLoading1] = useState(false);
  const approveMemo = () => {
    setLoading1(true);
    _postApi(
      "/account/insert-approved-memo",
      {
        type: "memo",
        name: user.username,
        role: user.role,
        id_link: items.memo_id,
        remark: "Approve By MD",
        user_id: user.id,
        amount: items.amount,
        status: "Approval",
        query_type: "-else",
        logStatus: "Review",
      },
      (res) => {
        if (res.success) {
          _customNotify("Successfully Submit");
          setLoading1(false);
          toggle();
          getMemos();
        }
      },
      (err) => {
        _warningNotify("error occured");
        console.log(err);
        setLoading1(false);
      }
    );
  };

  useEffect(() => {
    getMemos();
  }, [getMemos]);
  const [isOpen, setIsOpen] = useState(false);
  const [items, setItems] = useState({});
  const toggle = (item) => {
    setItems(item);
    setIsOpen(!isOpen);
  };
  const cancel = (item) => {
    setItems({});
    setIsOpen(!isOpen);
  };
  const fields = [
    {
      title: "Date",
      custom: true,
      component: (item) => (
        <div className="text-left">{moment().format(item.date)}</div>
      ),
    },
    {
      title: "Memo No.",
      custom: true,
      component: (item) => <div className="text-center">{item.memo_id}</div>,
    },
    {
      title: "From",
      custom: true,
      component: (item) => <>{item.from_name}</>,
    },
    {
      title: "Status",
      custom: true,
      component: (item) => (
        <div className="text-center">
          <Badge color="danger">{item.status}</Badge>
        </div>
      ),
    },
    {
      title: "Action",
      custom: true,
      component: (item) => (
        <div className="text-center">
          <CustomButton
            color="success"
            size="sm"
            className="m-1"
            handleSubmit={() => {
              toggle(item);
            }}
          >
            <FaEye size="20" />
          </CustomButton>
        </div>
      ),
    },
  ];

  return (
    <CustomCard header="Memos" back>
      <Row>
        {loading && <Loading />}
        {!loading ? (
          <CustomTable data={memos} fields={fields} />
        ) : (
          <Alert className="mt-3" color="info">
            No data to view
          </Alert>
        )}
      </Row>
      <CustomModal
        isOpen={isOpen}
        toggle={toggle}
        header="Preview"
        footer={
          <>
            <Button color="danger" className="mr-1" outline onClick={cancel}>
              Skip
            </Button>
            <CustomButton
              onClick={approveMemo}
              loading={loading1}
              disabled={remark === "" ? true : false}
            >
              Submit
            </CustomButton>
          </>
        }
      >
        <div>
          <div
            style={{
              borderWidth: 1,
              borderColor: "#000",
              padding: 20,
              marginBottom: 20,
            }}
          >
            <h2
              style={{
                fontSize: 18,
                textAlign: "center",
                marginBottom: 10,
                textTransform: "uppercase",
              }}
            >
              Brainstorm IT Solutions
            </h2>
            <h4
              style={{
                fontSize: 12,
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              Internal Memo
            </h4>
          </div>

          <div style={{ marginBottom: 20 }}>
            <div style={{ flexDirection: "row", width: "100%" }}>
              <div
                style={{
                  marginBottom: 10,
                  fontSize: 12,
                  textTransform: "uppercase",
                  marginRight: 10,
                }}
              >
                The Managing Director,
              </div>
            </div>
            <div style={{ flexDirection: "row", width: "100%" }}>
              <div
                style={{
                  marginBottom: 10,
                  fontSize: 12,
                  textTransform: "uppercase",
                  marginRight: 10,
                }}
              >
                From: <b>{items?.from_name}</b>
              </div>
            </div>
            <div style={{ flexDirection: "row", width: "100%" }}>
              <div
                style={{
                  marginBottom: 10,
                  fontSize: 12,
                  textTransform: "uppercase",
                  marginRight: 10,
                }}
              >
                Date: <b>{moment().format(items?.date)}</b>
              </div>
            </div>
          </div>

          <div
            style={{ flexDirection: "column", width: "100%", marginBottom: 60 }}
          >
            <div style={{ flexDirection: "row", width: "100%" }}>
              <div
                style={{
                  marginBottom: 10,
                  fontSize: 14,

                  textTransform: "uppercase",
                  marginRight: 10,
                }}
              >
                Purpose: <b>{items?.purpose}</b>
              </div>
            </div>
            <div style={{ flexDirection: "row", width: "100%" }}>
              <div
                style={{
                  marginBottom: 0,
                  fontSize: 14,

                  textTransform: "uppercase",
                  marginRight: 10,
                }}
              >
                <Label>Remark</Label>
                <Input
                  value={remark}
                  type="textarea"
                  onChange={({ target: { value } }) => {
                    setRemark(value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </CustomCard>
  );
}

export default MemoReviewal;
