import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavbarText,
  //   NavLink,
} from "reactstrap";
import { NavLink, useHistory } from "react-router-dom";
import { FaCartPlus, FaLockOpen, FaUserAlt } from "react-icons/fa";
import { AiFillBook, AiFillSetting } from "react-icons/ai";
// import { MdAccountBalanceWallet, MdDns, MdStore } from 'react-icons/md';
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/auth";
import { hasAccess } from "../utilities";
import { GiPayMoney } from "react-icons/gi";
import { MdDashboard } from "react-icons/md";
import "./navStyle.css";

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const toggle = () => setIsOpen(!isOpen);
  const gotoLogin = () => history.push("/login");
  const _logout = () => dispatch(logout(gotoLogin()));

  const user = useSelector((state) => state.auth.user);
  const activeBusiness = useSelector((state) => state.auth.activeBusiness);

  return (
    <Navbar
      dark
      expand="lg"
      style={{
        minHeight: "50px",
        padding: 0,
        margin: 0,
        paddingRight: 5,
        backgroundColor: activeBusiness.primary_color,
      }}
      className="mb-1"
    >
      <NavbarBrand
        onClick={() => history.push("/app")}
        style={{ fontFamily: "cursive", cursor: "pointer" }}
        className="ml-2 mr-2 "
      >
        <img
          src={require("../../images/logo12.png").default}
          alt="inventria logo"
          className="mr-0 pr-0"
          style={{ height: "40px" }}
        />
      </NavbarBrand>
      <div>
        {/* <img src={require('../../images/default-avatar.png')} alt='avatar' className="" style={{height:'40px'}} /> */}
        <NavbarToggler onClick={toggle} />
      </div>

      <Collapse isOpen={isOpen} navbar>
        <Nav className="mr-auto justify-content-between" navbar>
          {user.accessTo
            ? hasAccess(user, ["Dashboard"]) && (
              <NavItem className="navItem">
                <NavLink to="/app/dashboard" className="nav">
                  <MdDashboard
                    size={25}
                    style={{ marginRight: 5, paddingLeft: 7 }}
                  />
                  Dashboard
                </NavLink>
              </NavItem>
            )
            : null}

          {user.accessTo
            ? hasAccess(user, ["Purchases"]) && (
              <NavItem className="navItem">
                <NavLink to="/app/purchase" className="nav">
                  <FaCartPlus
                    size={25}
                    style={{ marginRight: 5, paddingLeft: 7 }}
                  />
                  Purchase
                </NavLink>
              </NavItem>
            )
            : null}
          {/* {user.accessTo
            ? hasAccess(user, ["Inventory"]) && (
                <NavItem className="navItem">
                  <NavLink to="/app/inventory" className="nav">
                    <MdStore
                      size={25}
                      style={{ marginRight: 5, paddingLeft: 7 }}
                    />
                    Inventory
                  </NavLink>
                </NavItem>
              )
            : null} */}
          {user.accessTo
            ? hasAccess(user, ["Customers"]) && (
              <NavItem className="navItem">
                <NavLink to="/app/customer" className="nav">
                  <GiPayMoney size={20} style={{ marginRight: 5 }} />
                  Customer
                </NavLink>
              </NavItem>
            )
            : null}

          {/* <NavItem className="navItem">
              <NavLink to="/app/store" className="nav">
                <MdStore size={20} style={{ marginRight: 5 }} />
                Store
              </NavLink>
            </NavItem>  */}
          {/* <NavItem className="navItem">
              <NavLink to="/app/production" className="nav">
                <MdDns size={25} style={{ marginRight: 5, paddingLeft: 7 }} />
                Production
              </NavLink>
            </NavItem>  */}
          {user.accessTo
            ? hasAccess(user, ["Custom Sales"]) && (
              <NavItem className="navItem">
                <NavLink
                  to="/app/custom-sales?tab=Give Items"
                  className="nav"
                >
                  <MdDashboard
                    size={25}
                    style={{ marginRight: 5, paddingLeft: 7 }}
                  />
                  Agents Sales
                </NavLink>
              </NavItem>
            )
            : null}
          {user.accessTo
            ? hasAccess(user, ["Make Sales"]) && (
              <NavItem className="navItem">
                <NavLink to="/app/sales?tab=Make Sales" className="nav">
                  <span
                    className="mr-1 font-weight-bold"
                    style={{ marginRight: 5, paddingLeft: 7, fontSize: 18 }}
                  >
                    ₦
                  </span>
                  Sales
                </NavLink>
              </NavItem>
            )
            : null}
          {user.accessTo
            ? hasAccess(user, ["Settings"]) && (
              <NavItem className="navItem">
                <NavLink to="/app/setting" className="nav">
                  <AiFillSetting size={20} style={{ marginRight: 5 }} />
                  Settings
                </NavLink>
              </NavItem>
            )
            : null}
          {user.accessTo
            ? hasAccess(user, ["Reports"]) && (
              <NavItem className="navItem">
                <NavLink to="/app/report" className="nav">
                  <AiFillBook size={20} style={{ marginRight: 5 }} />
                  Report
                </NavLink>
              </NavItem>
            )
            : null}

          {user.accessTo
            ? hasAccess(user, ["Account"]) && ( 
              <NavItem className="navItem">
                <NavLink to="/app/account" className="nav">
                  <AiFillBook size={20} style={{ marginRight: 5 }} />
                  Account
                </NavLink>
              </NavItem>
             )
            : null}

          {/* {user.accessTo
            ? hasAccess(user, ["Reports"]) && (
                <NavItem className="navItem">
                  <NavLink to="/app/account" className="nav">
                    <MdAccountBalanceWallet
                      size={25}
                      style={{ marginRight: 5, paddingLeft: 7 }}
                    />
                    Reports
                  </NavLink>
                </NavItem>
              )
            : null} */}
          {/* 
          <NavItem className="navItem">
            <NavLink to="/app/finance" className="nav">
              <MdDns size={25} style={{ marginRight: 5, paddingLeft: 7 }} />
              Account
            </NavLink>
          </NavItem> */}

          {user.accessTo
            ? hasAccess(user, ["Admin"]) && (
              <NavItem className="navItem">
                <NavLink to="/app/admin" className="nav">
                  <FaUserAlt
                    size={25}
                    style={{ marginRight: 5, paddingLeft: 7 }}
                  />
                  Admin
                </NavLink>
              </NavItem>
            )
            : null}
        </Nav>
        <NavbarText
          className="bg-danger px-3 text-white font-weight-bold"
          onClick={_logout}
          style={{ cursor: "pointer", borderRadius: 5 }}
        >
          <FaLockOpen size={25} style={{ marginRight: 5, paddingLeft: 7 }} />{" "}
          Logout
        </NavbarText>
      </Collapse>
      {/* <img src={require('../../images/default-avatar.png')} alt='avatar' className="" style={{height:'40px'}} /> */}
    </Navbar>
  );
}

export default NavBar;
