import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { CardBody, Col, Container, Input, Label, Row } from "reactstrap";
import CustomCard from "../components/CustomCard";
import CustomButton from "../../app/components/Button";
import { _fetchApi, _postApi, apiURL } from "../../redux/actions/api";
import { useSelector } from "react-redux";
import { _customNotify, _warningNotify } from "../../redux/helper";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import BackButton from "../../app/components/BackButton";
import SelectInput from "../components/SelectInput";
import useQuery from "../hooks/useQuery";
import TextInput from "../components/TextInput";
import { formatNumber, formatNumber1 } from "../../app/utilities";
export default function Memo() {
  const { user, activeBusiness } = useSelector((state) => state.auth);
  const query = useQuery();
  const id = query.get("id");
  const _form = {
    mode_of_payment: "",
    amount: "",
    date: moment().format("YYYY-MM-DD"),
    purpose: "",
  };
  const [data, setData] = useState({});
  const getMemos = useCallback(() => {
    _fetchApi(
      `/account/get-memo-by-id/${activeBusiness.id}/Approval/${id}`,
      (data) => {
        setLoading(false);
        if (data.success) {
          setData(data.results[0]);
        }
      }
    );
  }, [activeBusiness.id, id]);
  useEffect(() => {
    getMemos();
  }, [getMemos]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(_form);

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  let formData = new FormData();
  const handlefile = ({ target: { name, files } }) => {
    setForm((p) => ({ ...p, [name]: files[0] }));
    formData.append("document", files[0]);
    console.log(files[0]);
  };
  console.log(form);
  console.log(formData.values());
  const handleAdd = () => {
    setLoading(true);
    fetch(`${apiURL}/account/insert-pv`, {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: "dfgh" },
      body: JSON.stringify({ ...form, formData }),
    })
      .then((res) => console.log(res))
      .catch((err) => {
        _warningNotify("error occured");
        console.log(err);
        setLoading(false);
      });
    // _postApi(
    //       "/account/insert-pv",
    //       {
    //         ...form,
    //         formData
    //       },
    //   (res) => {
    //     if (res.success) {
    //       _customNotify("Successfully Submit");
    //       setLoading(false);
    //     //   history.goBack();
    //     }
    //   },
    //   (err) => {
    //     _warningNotify("error occured");
    //     console.log(err);
    //     setLoading(false);
    //   }
    // );
  };

  return (
    <div>
      <BackButton />
      <CustomCard header="Create Pv" className="mt-1 pt-1">
        <Container>
          <CardBody>
            <Row>
              <Col md={12} className="pb-3">
                <Label>Mode of Payment</Label>
                <SelectInput
                  options={["Cash", "POS", "Transfer", "Credit", "Cheque"]}
                  name="mode_of_payment"
                  value={form.mode_of_payment}
                  onChange={handleChange}
                />
              </Col>
              {form.mode_of_payment === "Cheque" ? (
                <Col md={12} className="pb-3">
                  <Label>Cheque No.</Label>
                  <TextInput
                    type="text"
                    name="cheque_no"
                    value={form.cheque_no}
                    onChange={handleChange}
                  />
                </Col>
              ) : null}
              <Col md={12} className="pb-3">
                <Label>
                  Amount({formatNumber1(form.amount)})
                </Label>
                <TextInput
                  type="number"
                  name="amount"
                  value={form.amount}
                  onChange={handleChange}
                />
              </Col>
              <Col md={12} className="pb-3">
                <Label>Purpose</Label>
                <TextInput
                  onChange={handleChange}
                  name="purpose"
                  value={form.purpose}
                  type="textarea"
                />
              </Col>
              {/* <Col md={6} className="pb-3">
                <Label>Attached Document</Label>
                <Input
                  onChange={handlefile}
                  name="document"
                  type="file"
                  className="form-control pb-2"
                />
              </Col> */}
            </Row>
            <center>
              <CustomButton
                loading={loading}
                className="mt-3"
                onClick={handleAdd}
              >
                Create
              </CustomButton>
            </center>
          </CardBody>
        </Container>
      </CustomCard>
    </div>
  );
}
