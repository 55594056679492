import React, { useEffect, useState } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import CustomButton from "../../app/components/Button";
import CustomCard from "../components/CustomCard";
import { _deleteApi, _postApi, unflatten } from "../../redux/actions/api";
import StructureTree2 from "./StructureTree";
import { toaster } from "evergreen-ui";

export default function SetupChartOfAccount() {
  const _form = {
    head: "",
    search: "",
    sub_head: "",
    description: "",
  };
  const [form, setForm] = useState(_form);
  const [data, setData] = useState([]);
  const [isEditing, setIsEditing] = useState(false); // New state for tracking editing mode
  const [chart, setChart] = useState();

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const getACCt = () => {
    _postApi(
      `/account/chart-of-account?query_type=select`,
      {},
      (resp) => {
        if (resp.success) {
          const result = resp.results;
          const chartData = unflatten(result);
          setChart(chartData);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const handleCreate = () => {
    const endpoint = isEditing ? "update" : "create"; 
    console.log(form)
    _postApi(
      `/account/chart-of-account?query_type=${endpoint}`,
      form,
      (resp) => {
        if (resp.success) {
          toaster.success(`${isEditing ? "Updated" : "Created"} successfully`);
          getACCt();
          setForm(_form);
          setIsEditing(false);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const handleDeleteNode = (node) => {
    _postApi(
      `/account/chart-of-account?query_type=delete`,
      { head: node.head },
      (resp) => {
        if (resp.success) {
          toaster.success("Deleted successfully");
          getACCt();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    getACCt();
  }, []);

  return (
    <div>
      <CustomCard header="Chart of Account Setup">
        <Row>
          <Col md="4" className="pr-0">
            <Col md="12" className="mt-2">
              <Label>Account Subhead Code</Label>
              <Input
                type="text"
                name="sub_head"
                value={form.sub_head}
                onChange={handleChange}
                disabled
              />
            </Col>
            <Col md="12">
              <Label>Select Head</Label>
              <Input
                type="text"
                name="head"
                value={form.head}
                onChange={handleChange}
              />
            </Col>
            <Col md="12" className="mt-2">
              <Label>Description</Label>
              <Input
                type="text"
                name="description"
                value={form.description}
                onChange={handleChange}
              />
            </Col>
          </Col>
          <Col md="8" className="pl-0">
            <StructureTree2
              treeData={chart}
              addChild={(hey) => {
                setForm((p) => ({
                  ...p,
                  sub_head: parseInt(hey.head),
                }));
              }}
              editNode={(node) => {
                setForm((p) => ({
                  ...p,
                  sub_head: parseInt(node.subhead),
                  head: parseInt(node.head),
                  description: node.description,
                  id: node.id
                }));
                setIsEditing(true);
              }}
              deleteNode={handleDeleteNode}
            />
          </Col>
        </Row>
        <CustomButton className="mt-3" onClick={handleCreate}>
          {isEditing ? "Update" : "Create"}
        </CustomButton>
      </CustomCard>
    </div>
  );
}
