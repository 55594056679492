export const invoiceNumber = [
    { value: "Select", label: "Select" },
    { value: "INV681537", label: "INV681537" },
    { value: "INV681536", label: "INV681536" },
    { value: "INV681535", label: "INV681535" },
  ];
  export const paymentMethod = [
    { value: "Select", label: "Select" },
    { value: "Cash", label: "Cash" },
    { value: "Credit", label: "Credit" },
  ];
  export const transactionDate = [
    { value: "Select", label: "Select" },
    { value: "25 Apr 2024", label: "25 Apr 2024" },
    { value: "27 Apr 2024", label: "27 Apr 2024" },
    { value: "03 May 2024", label: "03 May 2024" },
  ];
  export const customerName = [
    { value: "Add Customer", label: "Add Customer" },
    { value: "Ralph Claudia", label: "Ralph Claudia" },
    { value: "Julie Scott", label: "Julie Scott" },
    { value: "Maria", label: "Maria" },
  ];
  export const productName = [
    { value: "Select", label: "Select" },
    { value: "STEM Program Grant", label: "STEM Program Grant" },
    { value: "Uniform", label: "Uniform" },
    { value: "Library Donation", label: "Library Donation" },
  ];