import React, { useCallback, useEffect, useState } from "react";
import {
  Document,
  Image,
  Page,
  PDFViewer,
  StyleSheet,
  View,
  Text,
  Font,
} from "@react-pdf/renderer";
import DM_SANS_NORMAL from "../../assets/img/DM_Sans/DM_Sans/static/DMSans_24pt-SemiBold.ttf";
import DM_SANS_BOLD from "../../assets/img/DM_Sans/DM_Sans/static/DMSans_24pt-Bold.ttf";
import DM_SANS_ITALIC from "../../assets/img/DM_Sans/DM_Sans/static/DMSans-Italic.ttf";
import { _fetchApi } from "../../redux/actions/api";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { formatNumber1 } from "../../app/utilities";
import BackButton from "../../app/components/BackButton";
import useQuery from "../hooks/useQuery";
import moment from "moment";

Font.register({
  family: "DM_SANS",
  fonts: [
    { src: DM_SANS_NORMAL, fontWeight: 700 },
    {
      src: DM_SANS_BOLD,
      fontStyle: "bold",
    },
    {
      src: DM_SANS_ITALIC,
      fontStyle: "italic",
    },
  ],
});
export default function MemoPDF() {
  const { activeBusiness, user } = useSelector((state) => state.auth);
  const query = useQuery();
  const id = query.get("id");
  const [data, setData] = useState([]);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const param = useParams();
  console.log(param);

  const getMemos = useCallback(() => {
    _fetchApi(
      `/account/get-memo-by-id/${activeBusiness.id}/Approval/${param.id}`,
      (data) => {
        setLoading(false);
        if (data.success) {
          setData(data.results[0]);
        }
      }
    );
  }, [activeBusiness.id, param.id]);

  const getLogs = useCallback(() => {
    _fetchApi(
      `/account/get-logs?id=${id}`,
      (data) => {
        setLoading(false);
        if (data.success) {
          setLogs(data.results[0]);
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  }, [id]);

  useEffect(() => {
    getMemos();
    getLogs();
  }, [getLogs, getMemos]);
  let approved = logs.filter((item) => item.status === "approved");
  let review = logs.filter((item) => item.status === "Review");
  let _review = review[0];
  return (
    <>
      <BackButton />
      <PDFViewer
        style={{
          width: "100%",
          height: "97vh",
        }}
      >
        <Document>
          <Page style={styles.page} size="A4">
            <View
              style={{
                borderWidth: 1,
                borderColor: "#000",
                padding: 20,
                marginBottom: 20,
              }}
            >
              <Text
                style={{
                  fontSize: 18,
                  textAlign: "center",
                  marginBottom: 10,
                  textTransform: "uppercase",
                }}
              >
                Brainstorm IT Solutions
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                Internal Memo
              </Text>
            </View>

            <View style={{ marginBottom: 60 }}>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text
                  style={{
                    marginBottom: 10,
                    fontSize: 12,
                    textTransform: "uppercase",
                    marginRight: 10,
                  }}
                >
                  The Managing Director,
                </Text>
              </View>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text
                  style={{
                    marginBottom: 10,
                    fontSize: 12,
                    textTransform: "uppercase",
                    marginRight: 10,
                  }}
                >
                  From:
                </Text>
                <Text
                  style={{
                    marginBottom: 5,
                    fontSize: 12,
                    textTransform: "uppercase",
                    // marginRight: 5
                  }}
                >
                  <Text>{data?.from_name}</Text>
                </Text>
              </View>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text
                  style={{
                    marginBottom: 10,
                    fontSize: 12,
                    textTransform: "uppercase",
                    marginRight: 10,
                  }}
                >
                  Date:
                </Text>
                <Text
                  style={{
                    marginBottom: 5,
                    fontSize: 12,
                    textTransform: "uppercase",
                    // marginRight: 5
                  }}
                >
                  <Text>{data?.date}</Text>
                </Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "column",
                width: "100%",
                marginBottom: 60,
              }}
            >
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text
                  style={{
                    marginBottom: 10,
                    fontSize: 12,
                    textTransform: "uppercase",
                    marginRight: 10,
                  }}
                >
                  Purpose:
                </Text>
                <Text
                  style={{
                    width: "100%",
                    textAlign: "justify",
                    marginBottom: 5,
                    fontSize: 12,
                    textTransform: "uppercase",
                  }}
                ></Text>
              </View>

              <Text
                style={{
                  marginBottom: 5,
                  fontSize: 12,
                  textTransform: "uppercase",
                  // marginRight: 5
                }}
              >
                {data?.purpose}
              </Text>
            </View>

            <View style={{ marginBottom: 60 }}>
              {approved.map((log, index) => (
                <View key={index} style={{ marginBottom: 60 }}>
                  <View style={{ flexDirection: "row", width: "100%" }}>
                    <Text
                      style={{
                        marginBottom: 10,
                        fontSize: 12,
                        textTransform: "uppercase",
                        marginRight: 10,
                      }}
                    >
                      Approved by:
                    </Text>
                    <Text
                      style={{
                        marginBottom: 5,
                        fontSize: 12,
                        textTransform: "uppercase",
                      }}
                    >
                      {log?.name}
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row", width: "100%" }}>
                    <Text
                      style={{
                        marginBottom: 10,
                        fontSize: 12,
                        textTransform: "uppercase",
                        marginRight: 10,
                      }}
                    >
                      Approved Amount:
                    </Text>
                    <Text
                      style={{
                        marginBottom: 5,
                        fontSize: 12,
                        textTransform: "uppercase",
                      }}
                    >
                      {formatNumber1(data?.amount)}
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row", width: "100%" }}>
                    <Text
                      style={{
                        marginBottom: 10,
                        fontSize: 12,
                        textTransform: "uppercase",
                        marginRight: 10,
                      }}
                    >
                      Sign:
                    </Text>
                    <Text
                      style={{
                        marginBottom: 5,
                        fontSize: 12,
                        textTransform: "uppercase",
                      }}
                    >
                      ................................................................
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row", width: "100%" }}>
                    <Text
                      style={{
                        marginBottom: 10,
                        fontSize: 12,
                        textTransform: "uppercase",
                        marginRight: 10,
                      }}
                    >
                      Date:
                    </Text>
                    <Text
                      style={{
                        marginBottom: 5,
                        fontSize: 12,
                        textTransform: "uppercase",
                      }}
                    >
                      {moment(log.createdAt).format("YYYY-MM-DD")}
                    </Text>
                  </View>
                </View>
              ))}

              <View style={{ marginBottom: 60 }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text
                    style={{
                      marginBottom: 10,
                      fontSize: 12,
                      textTransform: "uppercase",
                      marginRight: 10,
                    }}
                  >
                    Review by:
                  </Text>
                  <Text
                    style={{
                      marginBottom: 5,
                      fontSize: 12,
                      textTransform: "uppercase",
                    }}
                  >
                    {_review?.name}
                  </Text>
                </View>

                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text
                    style={{
                      marginBottom: 10,
                      fontSize: 12,
                      textTransform: "uppercase",
                      marginRight: 10,
                    }}
                  >
                    Sign:
                  </Text>
                  <Text
                    style={{
                      marginBottom: 5,
                      fontSize: 12,
                      textTransform: "uppercase",
                    }}
                  >
                    ................................................................
                  </Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text
                    style={{
                      marginBottom: 10,
                      fontSize: 12,
                      textTransform: "uppercase",
                      marginRight: 10,
                    }}
                  >
                    Date:
                  </Text>
                  <Text
                    style={{
                      marginBottom: 5,
                      fontSize: 12,
                      textTransform: "uppercase",
                    }}
                  >
                    {moment(_review?.createdAt).format("YYYY-DD-MM")}
                  </Text>
                </View>
              </View>
              <View style={{ marginBottom: 60 }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text
                    style={{
                      marginBottom: 10,
                      fontSize: 12,
                      textTransform: "uppercase",
                      marginRight: 10,
                    }}
                  >
                    Accountant:
                  </Text>
                  <Text
                    style={{
                      marginBottom: 5,
                      fontSize: 12,
                      textTransform: "uppercase",
                    }}
                  >
                    {user.username}
                  </Text>
                </View>

                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text
                    style={{
                      marginBottom: 10,
                      fontSize: 12,
                      textTransform: "uppercase",
                      marginRight: 10,
                    }}
                  >
                    Sign:
                  </Text>
                  <Text
                    style={{
                      marginBottom: 5,
                      fontSize: 12,
                      textTransform: "uppercase",
                    }}
                  >
                    ................................................................
                  </Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text
                    style={{
                      marginBottom: 10,
                      fontSize: 12,
                      textTransform: "uppercase",
                      marginRight: 10,
                    }}
                  >
                    Date:
                  </Text>
                  <Text
                    style={{
                      marginBottom: 5,
                      fontSize: 12,
                      textTransform: "uppercase",
                    }}
                  >
                    {moment().format("YYYY-DD-MM")}
                  </Text>
                </View>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
}

const styles = StyleSheet.create({
  page: {
    padding: 40,
  },
  section: {
    marginBottom: 20,
    width: "100%",
  },
  header: {
    fontSize: 18,
    textAlign: "center",
    marginBottom: 20,
    textTransform: "uppercase",
  },
  subHeader: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 30,
  },
  label: {
    marginBottom: 5,
    fontSize: 12,
    textTransform: "uppercase",
  },
  input: {
    width: "80%",
    borderBottom: 1,
    marginBottom: 20,
    fontSize: 12,
    paddingBottom: 5,
  },
});
